<template>
  <Row>
    <Column class="guest-review__user-avatar">
      <UserAvatar :params="{avatar: params.commenter.avatar, size: 50}"/>
    </Column>
    <Column>
      <p class="guest-review__name">{{params.commenter.name}}</p>
      <Rating class="guest-review__rating"
              :params="{id: params.id,
              type: 'comments',
              rating: params.rating,
              currentUserRating: params.currentUserRating}"/>
      <p class="guest-review__message">{{params.comment}}</p>
      <Row align="center"
           justify="start"
           class="guest-review__date">
        <Row align="center">
          <Icon class="guest-review__date-icon"
                viewport="0 0 20 20"
                xlink="calender"/>
          <span class="guest-review__date-time">
            {{$moment.unix(params.created_at).format('DD MMMM YYYY')}}</span>
        </Row>
        <div class="guest-review__leave-comment"
             v-on:click="$emit('show-form', true)">Ответить
        </div>
      </Row>
    </Column>
  </Row>
</template>

<script>
export default {
  name: 'GuestReviewDesktop',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
};
</script>
